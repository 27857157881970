:root {
	--border: 1px solid #f1f2f4;
	--border-2: 2px solid #f1f2f4;
	--border-half: 0.5px solid #f1f2f4;
	--color-text-green: #00af89;
	--color-text-red: #ff3b3b;
	--color-background-trade: #ffffff;
	--color-background-mobile: #f6f6f9;
	--color-slider-rail: #f7f7f7;
	--color-slider-mark: #d8dbdf;
	--color-select-background: #f7f7f7;
	--color-select-background-selected: #e5e7ea;
	--color-transfer-account-type-bg: #ffffff;
	--color-scrollbar_background: #e5e7ea;
	--color-tour-background: #ffffff;
	--color-text-select: #bdbdbd;
	--mobile-text-red: #ff3b30;
	--mobile-text-green: #2ebd85;
}

[data-theme='dark'] {
	--border: 1px solid #000;
	--border-2: 2px solid #000;
	--border-half: 0.5px solid #000;
	--color-text-green: #7affe2;
	--color-text-red: #f0565a;
	--color-background-trade: #181819;
	--color-background-mobile: #191d24;
	--color-slider-rail: #272d33;
	--color-slider-mark: #353e47;
	--color-select-background: #272d33;
	--color-select-background-selected: #3d4752;
	--color-transfer-account-type-bg: #3d4752;
	--color-scrollbar_background: #353d45;
	--color-tour-background: #353e47;
	--color-text-select: #989ca4;
	--mobile-text-red: #ff7070;
	--mobile-text-green: #7ddea4;
}

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');
html {
	font-size: 16px;
}

.green_txt {
	color: var(--color-text-green) !important;
}

.red_txt {
	color: var(--color-text-red) !important;
}

.yellow_txt {
	color: var(--color-primary-800) !important;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: var(--font-product-sans), sans-serif !important;
}

body {
	height: 100vh;
	background: var(--color-background-trade);
	min-width: 1400px;
	overflow: auto;
}

@media (max-width: 600px) {
	body {
		height: 100vh;
		background: var(--color-background-mobile);
		min-width: unset;
	}
}

a {
	text-decoration: none;
}

table {
	border-collapse: collapse;
}

::-webkit-scrollbar {
	width: 0;
}

@media (min-width: 600px) {
	::-webkit-scrollbar {
		width: 5px;
		height: 0;
	}
	::-webkit-scrollbar-track {
		background: var(--color-scrollbar_background);
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: var(--color-primary);
		border-radius: 10px;
		border: 1px solid black;
	}
	::-webkit-scrollbar-thumb:hover {
		background: rgb(85, 94, 129);
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
	/* Firefox */
}

.MuiPaper-root {
	border: 1px solid black;
	border-radius: 4px !important;
	padding: 4px;
	background-color: var(--color-select-background) !important;
	background-image: none !important;
}

.MuiList-root {
	padding: 0 !important;
	display: flex !important;
	flex-direction: column;
	gap: 2px;
}

.MuiMenuItem-root {
	padding: 3px 7px !important;
	justify-content: center !important;
	font-size: 0.75rem !important;
	font-weight: 300 !important;
}

.MuiMenuItem-root.Mui-selected {
	background-color: var(--color-select-background-selected) !important;
	border-radius: 2px;
	font-weight: 500 !important;
	color: var(--color-text-1) !important;
}

@keyframes FADE_IN {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes FADE_OUT {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes PAIR_LIST_IN {
	0% {
		scale: 0;
	}
	100% {
		scale: 1;
	}
}

@keyframes PAIR_LIST_OUT {
	0% {
		scale: 1;
	}
	100% {
		scale: 0;
	}
}

@keyframes MODAL_BODY_IN {
	0% {
		scale: 0;
	}
	100% {
		scale: 1;
	}
}

@keyframes MODAL_BODY_OUT {
	0% {
		scale: 1;
	}
	100% {
		scale: 0;
	}
}

@keyframes MOBILE_MODAL_IN {
	0% {
		bottom: -600px;
	}
	100% {
		bottom: 0;
	}
}

@keyframes MOBILE_MODAL_OUT {
	0% {
		bottom: 0;
	}
	100% {
		bottom: -600px;
	}
}

@keyframes MOBILE_FULL_MODAL_IN {
	0% {
		left: -100%;
	}
	100% {
		left: 0;
	}
}

@keyframes MOBILE_FULL_MODAL_OUT {
	0% {
		left: 0;
	}
	100% {
		left: -100%;
	}
}

@keyframes MOBILE_FULL_MODAL_IN_FROM_RIGHT {
	0% {
		right: -100%;
	}
	100% {
		right: 0;
	}
}

@keyframes MOBILE_FULL_MODAL_OUT_FROM_RIGHT {
	0% {
		right: 0;
	}
	100% {
		right: -100%;
	}
}

@keyframes PREFERENCES_SLIDE_IN {
	0% {
		right: -400px;
	}
	100% {
		right: 0;
	}
}

@keyframes PREFERENCES_SLIDE_OUT {
	0% {
		right: 0;
	}
	100% {
		right: -400px;
	}
}

.clickable {
	cursor: pointer;
}

[data-tour-elem] {
	display: none !important;
}

.reactour__close {
	display: none !important;
}

.reactour__helper {
	padding: 24px !important;
	border-radius: 8px !important;
	min-width: 322px !important;
	background-color: var(--color-tour-background) !important;
}

.reactour__helper::before {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 12px;
	display: block;
	width: 0px;
}

.reactour__helper[helperposition='left']::before {
	border-color: transparent transparent transparent var(--color-tour-background);
	right: -20px;
	top: 10px;
}

.reactour__helper[helperposition='bottom']::before {
	border-color: transparent transparent var(--color-tour-background) transparent;
	left: 20px;
	top: -20px;
}

.reactour__helper[helperposition='top']::before {
	border-color: var(--color-tour-background) transparent transparent transparent;
	left: 20px;
	bottom: -20px;
}

.select_icon_item {
	display: flex;
	gap: 8px;
	width: 100%;
}

.select_icon_item div {
	color: var(--color-text-1);
	font-weight: 500;
	font-size: 0.87rem;
}

@media (max-width: 600px) {
	.green_txt {
		color: var(--mobile-text-green) !important;
	}
	.red_txt {
		color: var(--mobile-text-red) !important;
	}
	.MuiPaper-root {
		border: 1px solid #e5e5ea !important;
		border-radius: 8px !important;
		padding: 0 !important;
		background-color: var(--color-select-background) !important;
		background-image: none !important;
		box-shadow: none !important;
	}
	.MuiList-root {
		padding: 0 !important;
		display: flex !important;
		flex-direction: column;
		gap: 2px;
	}
	.MuiMenuItem-root {
		padding: 0 !important;
		justify-content: center !important;
		font-size: 0.9rem !important;
		font-weight: 600 !important;
		color: var(--color-text-select) !important;
		min-height: 40px !important;
	}
	.MuiMenuItem-root.Mui-selected {
		background-color: transparent !important;
		border-radius: 0 !important;
		font-weight: 500 !important;
		color: #fcd535 !important;
	}
	.MuiPaper-root .transfer_select {
		padding: 0 10px !important;
	}
}

.react-resizable-handle-se::after,
.react-resizable-handle-sw::after {
	border-right: 2px solid var(--color-slider-mark) !important;
	border-bottom: 2px solid var(--color-slider-mark) !important;
}
